/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
  var timeout;
  return function () {
      var context = this, args = arguments;
      var later = function () {
          timeout = null;
          if (!immediate) {
              func.apply(context, args);
          }
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) {
          func.apply(context, args);
      }
  };
}

// Flickity SVG arrow shapes
var arrowShape = 'M4.87659848,50 L50,95.1234014 L50,100 L0,50 L50,0 L50,4.87659848 Z';
var arrowShapeLarge = 'M2.87659848,50 L50,97.1234014 L50,100 L0,50 L50,0 L50,2.87659848 Z';

(function($) {
  // Document Ready Javascript
  $(document).ready(function () {

    // **********************************
    // Initial Preload with ImagesLoaded
    // **********************************

    // Collects all images on a given
    // page and reveals when they have
    // completed loading.

    function imagesLazyLoaded() {
      $('img[loading="lazy"]').imagesLoaded().progress( function( instance, image ) {
        if ( image.isLoaded ) {
          $(image.img).addClass('is-lazy-loaded');
        }
      });
    }

    function checkInstagramImages() {
      $('figure.instagram img').each(function() {
        var $img = $(this);
        var orientation = 'ig-square';

        var width = $img.eq(0).innerWidth();
        var height = $img.eq(0).innerHeight();

        if (width > height) {
            orientation = 'ig-landscape';
        } else if (width < height) {
            orientation = 'ig-portrait';
        }

        $img.attr('class', orientation);
      });
    }

    function collectImages() {
      var $images = $('.imagesloaded').first();

      // Collect any BG images we want to include in the imagesloaded check
      $('.has-bg').each(function () {
        var el = $(this);
        var sources = el.css('background-image');
        if (sources) {
          $.each(sources.split(','), function (i, source) {
            var image = source.match(/url\((['"])?(.*?)\1\)/);
            if (image) {
              $images = $images.add($('<img>').attr('src', image.pop()));
            }
          });
        }
      });

      return $images;
    }

    // On First Load
    $('.pre-loading-logo').imagesLoaded({ background: true }).progress(function (instance, image) {
      $(image.element).addClass('loaded');
    }).always(function (instance) {
      var imgLoad = imagesLoaded(collectImages());

      imgLoad.on('always', function () {
        // Scroll to position of linked post
        if (window.location.hash) {
          var hash = window.location.hash;

          $('html, body').animate({
            scrollTop: $(hash).offset().top
          }, 1500);
        }

        checkInstagramImages();
      });

      // Fade out pre-load overlay
      $('#pre-loading').fadeOut(1000);
    });

    imagesLazyLoaded();

    // **********************************
    // Reduce Item Opacity on Scroll
    // **********************************

    function handleScroll() {
      var scrollTop = $(document).scrollTop();

      //the max scroll offset you get when you're scrolled to the bottom
      var maxScrollOffset = $('body').height();

      //gives you a number from 0 to 1 representing the percent they've scrolled:
      var scrollPercent = scrollTop / maxScrollOffset;

      //THE TOP ARROW
      //set the opacity from 0 to 1 when the percent is 0 to 0.5
      if (scrollPercent < 0.5) {
        var opacity = scrollPercent * 2;
        $('.to-top').css({ opacity: opacity });
      } else {
        $('.to-top').css({ opacity: 1 });
      }

      // FOOTER CONTACT SECTION
      // opacity 1 until the contact section almost hits the footer
      var $footer = $('.content-info');
      var $contact = $('.contact-info');
      var $contact_fade = $('.contact-fade');

      contact_footer_distance = $footer.offset().top - $contact.offset().top - 89;

      if (contact_footer_distance < 500) {
        // var normalized_distance = 500 - contact_footer_distance;
        $contact_fade.css({ opacity: 1 - ((500 - contact_footer_distance) / 500) });
      } else {
        $contact_fade.css({ opacity: 1 });
      }

      //PROJECT HEADER
      //when scrolling from 0 up to the window height, opacity goes from 1 to 0
      var $pHead = $('.lead-wrapper');
      if (scrollTop < $(window).height()) {
        var headerOpacity = 1 - scrollTop / $(window).height();
        $pHead.css({ opacity: headerOpacity });
      } else {
        $pHead.css({ opacity: 0 });
      }
    }

    //setInterval(handleScroll, 1000 / 60);
    $(window).scroll(handleScroll);

    // **********************************
    // PJAX
    // **********************************

    // Initialize PJAXpjax it
    $(document).pjax('a', '#container', {
      timeout: 5000,
      fragment: '#container',
    });

    // Loading Overlay
    $('#container').on('pjax:complete', function (x, t, o) {
      imagesLazyLoaded();
      // checkInstagramImages();

      var imgLoad = imagesLoaded(collectImages());

      imgLoad.on('always', function () {
        // fade out loading overlay
        checkInstagramImages();
        $('#loading').fadeOut(1000);
      });
    });

    // Get new body classes from parent fragment and assign right before replace
    $('#container').on('pjax:beforeReplace', function (event, contents, options) {
      var newBodyClasses = $(contents).parent().data('bodyclass');

      if (newBodyClasses) {
        $('body').removeClass().addClass(newBodyClasses);
      }
    });

    // **********************************
    // Menu Functions
    // **********************************

    // Menu is outside of #container and
    // unaffected by PJAX transitions

    var menuToggle = function () {
      $('.menu-toggle-button').toggleClass('menu-active');
      $('.menu').toggleClass('menu-in');
      $('body').toggleClass('menu-active');

      if ($('.menu').hasClass('menu-hidden')) {
        $('.menu').removeClass('menu-hidden');
      } else if (!$('.menu').hasClass('menu-hidden')) {
        setTimeout(function () {
          if (!$('.menu').hasClass('menu-in')) {
            $('.menu').addClass('menu-hidden');
          }
        }, 400);
      }
    };

    var menuClose = function () {
      if (!$('.menu').hasClass('menu-hidden')) {
        $('.menu-toggle-button').removeClass('menu-active');
        $('.menu').removeClass('menu-in');
        $('body').addClass('menu-active');
        setTimeout(function () {
          $('.menu').addClass('menu-hidden');
          $('body').removeClass('menu-active');
        }, 400);
      }
    };

    // **********************************
    // Menu Events
    // **********************************

    $('.menu-toggle-button').click(function (e) {
      e.preventDefault();
      menuToggle();
    });

    $('.menu-item').click(function (e) {
      menuToggle();
    });

    $('button.to-top').on('click', function (e) {
      e.preventDefault();
      $('body, html').animate({
        scrollTop: 0,
      }, 600
      );
    });

    $('#container').on('pjax:beforeSend', function (e, xhr, options) {
      menuClose();

      $('#loading').fadeIn(200);
    });
  });

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // SET UP VARS
        var contentSizing = debounce(function () {
          var $leadWrapper = $('.lead-wrapper');
          var $contentWrapper = $('.content-wrapper');

          var windowHeight = $(window).innerHeight();
          // var containerHeight = windowHeight - $('.content-info').outerHeight();

          if ( $(window).innerWidth() < 768 ) {
            $('#homeSlider').css({height: windowHeight - 60});

            $leadWrapper.css({height: ''});
            $contentWrapper.css({marginTop: ''});

            $('#container').css({minHeight: ''});
            $contentWrapper.css({minHeight: ''});
          }

          if ( $(window).innerWidth() >= 768 ) {
            $('#homeSlider').css({height: ''});

            $leadWrapper.css({height: windowHeight});
            $contentWrapper.css({marginTop: windowHeight});

            $('#container').css({minHeight: windowHeight - 60});
            $contentWrapper.css({minHeight: windowHeight - 60});
          }
        });

        contentSizing();
        window.addEventListener('resize', contentSizing);

        // HOME & PROJECTS SCROLL PROMPT
        $('button.scroll-down').on('click', function(e) {
          e.preventDefault();

          var scrollToContent = $('.content-wrapper').offset().top;

          if ( $(window).innerWidth() < 768 ) {
            scrollToContent = $('.content-wrapper').offset().top - 60;
          }

          $('body, html').animate({
            scrollTop: scrollToContent,
            }, 600
          );
        });

        //Obfuscate email and phone numbers
        $('a[data-email]').each(function(){
          var obfuscated_addy = $(this).attr('data-email');
          $(this).removeAttr('data-email');
          var actual_addy = obfuscated_addy.replace('_#_', '@');
          var name = $(this).html();
          name = name === "" ? actual_addy : name;
          $(this).attr('href', 'mailto:'+actual_addy).filter(':not(.notext)').html(name);
        });

        $('a[data-phone]').each(function(){
          var obfuscated_phone = $(this).attr('data-phone');
          $(this).removeAttr('data-phone');
          var actual_phone = obfuscated_phone.replace(/_#_/g, '');
          var name = obfuscated_phone.replace(/_#_/g, ' ');
          $(this).attr('href', 'tel:'+actual_phone).filter(':not(.notext)').html(name);
        });

        // Video Cover
        $('.embed-cover').on('click mouseenter', function() {
          var $this = $(this);

          $this.addClass('hover');

          setTimeout(function() {
            $this.remove();
          }, 400);
        });

        // Video Modal
        var playButtons = $('.btn-play');

        if (playButtons.length > 0) {
          $('#watchVideo').on('show.bs.modal', function (event) {
            $(this).find('.video').addClass('loading');
          });

          $('#watchVideo').on('shown.bs.modal', function (event) {
            var button = $(event.relatedTarget);
            var iframeRaw = button.data('video');
            var videoContainer = $(this).find('.video');

            // Make into jQuery object
            var iframe = $(iframeRaw);
            // Store iframe source
            var iframeSrc = iframe.attr('src');

            // Remove source so iframe isn't loaded when we put it into the container
            iframe.attr('src', '');

            $(iframe).appendTo(videoContainer).on('load', function () {
              videoContainer.removeClass('loading');
            });

            // Fallback if for some reason the above doesn't work
            setTimeout(function () {
              videoContainer.removeClass('loading');
            }, 2000);

            // Add the source back
            iframe.attr('src', iframeSrc);
          });

          $('#watchVideo').on('hidden.bs.modal', function (event) {
            var iframe = $(event.target).find('iframe');

            iframe.remove();
          });
        }
      },
      finalize: function() {
      },
    },
    'home': {
      init: function () {
        var $homeSlider = $('#homeSlider').flickity({
          //imagesLoaded: true,
          lazyLoad: 2,
          pageDots: false,
          wrapAround: true,
          autoPlay: 5000,
          draggable: false,
          prevNextButtons: true,
          pauseAutoPlayOnHover: false,
          arrowShape: arrowShape,
          setGallerySize: false
        });

        var flkty = $homeSlider.data('flickity');

        var homeSliderDraggable = debounce(function () {
          if ( $(window).innerWidth() < 768 ) {
            flkty.options.draggable = true;
            flkty.updateDraggable();
          } else {
            flkty.options.draggable = false;
            flkty.updateDraggable();
          }
        });

        homeSliderDraggable();
        window.addEventListener('resize', homeSliderDraggable);

        function playOnSelect() {
          var video = flkty.selectedElement.querySelector('video');

          if ( video && typeof(video.play) === "function" ) {
            // Lazy load video sources
            if ( video.classList.contains('lazy-video') ) {
              for (var source in video.children) {
                var videoSource = video.children[source];
                if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
                  if ( !videoSource.src ) {
                    videoSource.src = videoSource.dataset.src;
                  }
                }
              }

              video.load();
              video.classList.remove("lazy-video");
              video.closest('.home-gallery-video').classList.add('video-lazyloaded');
            }

            video.play();

            // if flickity is not paused, pause flickity and continue when video ends
            if (flkty.player.state === "playing") {
              $homeSlider.flickity('pausePlayer');
              video.loop = false;

              video.addEventListener('ended', function() {
                $homeSlider.flickity('unpausePlayer').flickity('next');
              });
            } else {
              video.loop = true;
            }
          } else {
            var videos = flkty.element.querySelectorAll('video');

            videos.forEach(function(video) {
              video.pause();
            });
          }
        }

        // play initial video
        playOnSelect();
        // play video on select
        $homeSlider.on( 'select.flickity', playOnSelect );
      },
    },
    'page_template_template_people': {
      init: function () {
        // Bio Modal
        $('#bioModal').on('show.bs.modal', function (e) {
          var button = $(e.relatedTarget);
          var bio = $(button.data('bio')).clone();

          var modal = $(this);

          modal.find('.modal-bio-image').html(bio.find('.bio-image'));
          modal.find('.modal-bio-name').html(bio.find('.bio-name'));
          modal.find('.modal-bio-subtitle').html(bio.find('.bio-subtitle'));
          modal.find('.modal-bio-description').html(bio.find('.bio-description'));
        });
      },
    },
    'blog': {
      init: function () {
        // **********************************
        // News Article Carousels
        // **********************************
        var articleCarouselArgs = {
          contain: true,
          // pageDots: false,
          wrapAround: true,
          arrowShape: arrowShape,
          lazy: true,
          imagesLoaded: true,
        };

        $('.article-carousel').flickity(articleCarouselArgs);

        // $(window).on('post-load', function(e) {
        //   $(e.target).find('.article-carousel').flickity(articleCarouselArgs);
        // });

        // **********************************
        // AJAX auto-load posts
        // **********************************

        var $newsFeed = $('#newsFeed');

        // The Count variable is our page number.
        var count = 2;

        // Total variable is the total number of pages
        var total = $newsFeed.data('max-pages');

        $(window).scroll(function () {
          if ($(window).scrollTop() === $(document).height() - $(window).height()) {

            if (count <= total) {
              $('#inifiniteLoader').show('fast');

              $.ajax({
                url: shapearch.adminAjax,
                type: 'POST',
                data: {
                  action: 'infinite_scroll',
                  page_no: count,
                  loop_file: 'loop'
                },
                success: function (html) {
                  var $html = $(html);

                  $('#infiniteLoader').hide('1000');
                  $newsFeed.append($html);
                  $html.find('.article-carousel').flickity(articleCarouselArgs);
                  $('img[loading="lazy"]').imagesLoaded().progress( function( instance, image ) {
                    if ( image.isLoaded ) {
                      $(image.img).addClass('is-lazy-loaded');
                    }
                  });

                }
              });
            }

            count++;
          }
        });
      },
      finalize: function () {
      },
    },
    'post_type_archive_projects': {
      init: function () {
        // **********************************
        // Replace Value for N/A Items
        // **********************************

        var replaceSortVal = function (itemElem, selector) {
          var value = $(itemElem).find(selector).text().replace('N/A', '9999');
          return value;
        };

        // **********************************
        // Heights for Isotope Elements
        // **********************************

        var elementHeights = function () {
          var tallestChild = 0;

          $('.grid-project').height('auto');
          $('.grid-project').each(function () {
            tallestChild = ($(this).height() > tallestChild ? $(this).height() : tallestChild);
          }).height(tallestChild);

          if ($(window).width() >= 768) {
            $('.projects-nav').css({ height: $('#projectsArchive').find('.grid-item').height() });
          } else {
            $('.projects-nav').css({ height: 'auto' });
          }
        };

        elementHeights();

        // **********************************
        // SET UP ISOTOPE
        // **********************************

        // Set view to default state 'grid'
        // Changes to 'list' with switchView()
        var view = 'grid';
        var status_count = 9999;

        // Element Selection
        var $grid = $('#projectsArchive');
        var $gridButton = $('.grid-view-toggle');
        var $listButton = $('.list-view-toggle');
        var $gridHeader = $('.projects-nav');
        var $projectsContainer = $('#projectsArchive');
        var $listHeader = $('.projects-list-header');
        var $gridItem = $('.grid-project');

        $grid.isotope({
          itemSelector: '.grid-item',
          stamp: '.grid-stamp',
          imagesLoaded: true,
          isInitLayout: false,
          getSortData: {
            title: function (itemElem) {
              var title = replaceSortVal(itemElem, '.project-title');
              return title;
            },
            status: function (itemElem) {
              var status = replaceSortVal(itemElem, '.project-status');
              if (status.indexOf("Complete") >= 0) {
                var statusReturned = parseFloat(status.replace(/\D/g, ''));
                return statusReturned;
              } else {
                var statusIncomplete = status_count++;
                return statusIncomplete;
              }
            },
            location: function (itemElem) {
              var location = replaceSortVal(itemElem, '.project-location');
              return location;
            },
            size: function (itemElem) {
              var size = replaceSortVal(itemElem, '.project-size');
              return parseFloat(size);
            },
            budget: function (itemElem) {
              var budget = replaceSortVal(itemElem, '.project-budget');
              var budgetReturned = parseFloat(budget.replace('$', ''));
              return budgetReturned;
            }
          }
        });

        // Click Handler for GRID/LIST button
        function onArrange() {
          //$('.grid-item').css({'opacity': '1'});
          //reset transition duration to 0.4s (default)
          $grid.isotope({ transitionDuration: '0.4s' });
        }

        // Use imagesloaded to prevent messed up grid
        $grid.imagesLoaded().progress(function () {
          // Set Heights
          elementHeights();

          // Run isotope Layout
          $grid.isotope('layout');
        });

        $('.projects-list-sort').on('click', 'button', function () {
          var sortValue = $(this).attr('data-sort-value');
          $grid.isotope({ sortBy: sortValue });
        });

        function filterGrid(selector) {
          if (selector !== '*') {
            // include navigation in filter
            // so it never gets filtered out
            // there may be a better way ...
            selector = selector + ', .no-filter';
          }

          $grid.isotope({ filter: selector });
        }

        // Initial Filtering in case of popstate
        filterGrid($('.projects-nav button.active').data('filter'));

        // isotope filter navigation
        $('.projects-nav').on('click', 'button', function (e) {
          $('.projects-nav-ul').find('li button').removeClass('active');
          $(this).addClass('active');

          filterGrid($(this).data('filter'));
        });

        function switchView() {
          $projectsContainer.toggleClass('projects-archive-grid projects-archive-list');
          $gridItem.toggleClass('col-md-4');
          elementHeights();
          $grid.isotope({
            filter: '*'
          });
        }

        $('.project-archive-views button').click(function (e) {
          $target = $(e.target);

          $projectsContainer.fadeTo('slow', 0, function () {
            // set duration to 0 so no animation appears
            $grid.isotope({ transitionDuration: 0 });
            // bind event triggered once to reset transition duration
            // and opacity for smooth transition
            $grid.one('arrangeComplete', onArrange);
            if (view === 'list') {
              view = 'grid';
              $listHeader.addClass('hidden');
              $gridButton.attr('disabled', 'disabled');
              $listButton.removeAttr('disabled');
              $('.projects-nav-ul').find('li button').removeClass('active');
              $('.projects-nav-ul').find('li:first-child button').addClass('active');
              switchView();
            } else {
              view = 'list';
              $listHeader.toggleClass('hidden');
              $listButton.attr('disabled', 'disabled');
              $gridButton.removeAttr('disabled');
              switchView();
            }
          });
          //$('.grid-item').css({'opacity': '0'});

          $('#projectsArchive').fadeTo('slow', 1);
        });

        // **********************************
        // Window Resize Stuffs
        // **********************************

        $(window).resize(function () {
          elementHeights();
        });
      },
    },
    'single_projects': {
      init: function () {
        //Flickity Select Instantly
        $('.project-image-gallery').on('click', '.image-button', function (e) {
          var index = $(this).data('index');
          $('#projectLightbox').data('index', index);
          $('#projectLightbox').modal({
            backdrop: false
          });
        });

        $('#projectLightbox').on('show.bs.modal', function (e) {
          $contentWrapper.css({ transform: 'initial' });
        });

        $('#projectLightbox').on('hidden.bs.modal', function (e) {
          $contentWrapper.css({ transform: 'translateZ(0)' });
          $('#projectSlider').flickity('destroy');
        });

        $('#projectLightbox').on('shown.bs.modal', function (e) {
          var index = $('#projectLightbox').data('index');
          $('#projectSlider').flickity({
            //imagesLoaded: true,
            cellAlign: 'left',
            //lazyLoad: true,
            contain: true,
            initialIndex: index,
            pageDots: false,
            arrowShape: arrowShapeLarge
          });
        });
      },
    },
    'single_post': {
      init: function () {
        twttr.widgets.load();

        var articleCarouselArgs = {
          contain: true,
          // pageDots: false,
          wrapAround: true,
          arrowShape: arrowShape,
          imagesLoaded: true,
        };

        $('.article-carousel').flickity(articleCarouselArgs);
      },
    },
    'page_template_template_culture': {
      init: function () {
        $('.section-grid').each(function (index, element) {
          var $element = $(element);

          $element.imagesLoaded(function () {
            // $element.height($element.height());

            if ($element.hasClass('layout-auto')) {
              $element.isotope({
                imagesLoaded: true,
                itemSelector: '.grid-item',
                percentPosition: true,
              });
            }

            $element.find('.my-paroller').paroller({
              factorXs: 0.1,
            });
          });
        });
      },
    },
    'page_template_template_contact': {
      init: function () {
        mapboxgl.accessToken = 'pk.eyJ1IjoidGhlbnVtYmVyIiwiYSI6IkFQekRmbWsifQ.iy4Xr41-1GXPOfKmEO2x6Q';
        var map = new mapboxgl.Map({
          container: 'map',
          style: 'mapbox://styles/thenumber/cin0umxoe001ya8mefe0vrq86',
          center: [-123.136509, 49.264962],
          zoom: 16
        });

        map.scrollZoom.disable();
        map.addControl(new mapboxgl.Navigation());
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  $(document).on('pjax:end', UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
